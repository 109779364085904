// @ts-strict-ignore
import _ from 'lodash';
import { SEARCH_ITEM_LOCATIONS } from '@/main/app.constants';
import { sqFoldersApi } from '@/sdk';
import { flux } from '@/core/flux.module';
import { equalsIgnoreCase } from '@/utilities/utilities';
import { tabFolders } from '@/homescreen/homescreen.constants';
import { sqExploreFolderModalStore, sqHomeScreenStore } from '@/core/core.stores';
import { isEveryoneGroupEnabled } from '@/services/systemConfiguration.utilities';
import { isAdmin } from '@/services/authorization.service';
import { formatNestedFolderWithAncestors, getTabFolderName } from '@/utilities/homescreen.utilities';
import { getTabFolder } from '@/utilities/homescreen.helpers';

export const exportForTesting = {
  setInitialFolderId,
  setWorkbookId,
  setSort,
  clear,
  setTableFilter,
  setPageSizeForTable,
  setPageNumberAndGo,
  setSearchParams,
  loadItems,
  setFolder,
  getRootAndUpdateFilter,
  setShowResults,
  setTableRoot,
  getInitialFolder,
};

/**
 * Sets the ID of the folder where the user started
 *
 * @param {String} initialFolderId - ID of a folder
 */
export function setInitialFolderId(initialFolderId: string) {
  flux.dispatch('EXPLORE_FOLDER_MODAL_SET_INITIAL_FOLDER_ID', {
    initialFolderId,
  });
}

/**
 * Sets the workbook ID
 *
 * @param {String} workbookId - a workbook ID
 */
export function setWorkbookId(workbookId: string) {
  flux.dispatch('EXPLORE_FOLDER_MODAL_SET_WORKBOOK_ID', { workbookId });
}

/**
 * Sets the sort field that controls the sorting of the workbooks and toggles the direction if the field
 * was already set to the specified one.
 *
 * @param {String} sortProperty - One of WORKBOOKS_SORT_FIELDS
 * @param {boolean} sortAscending - True for asc, false for desc
 * @param {boolean} forceSortDirection=false - True if the given sort direction must not be flipped when sorting
 *   on the currently sorted property (normally used to toggle sort direction)
 */
export function setSort(sortProperty: string, ascending: boolean, forceSortDirection = false) {
  const sortAscending =
    !forceSortDirection && sortProperty === sqExploreFolderModalStore.sortProperty ? !ascending : ascending;
  flux.dispatch('EXPLORE_FOLDER_MODAL_SET_SORT', {
    sortProperty,
    sortAscending,
  });
}

/**
 * Clears the data for the currently loaded seeq content image.
 */
export function clear() {
  flux.dispatch('EXPLORE_FOLDER_MODAL_CLEAR');
}

export function setTableFilter(tableFilter) {
  flux.dispatch('EXPLORE_FOLDER_MODAL_SET_TABLE_FILTER', { tableFilter });
}

export function setSearchParams(field, value) {
  flux.dispatch('EXPLORE_FOLDER_MODAL_SET_SEARCH_PARAMS', { field, value });
}

export function setPageSizeForTable({ size }) {
  flux.dispatch('EXPLORE_FOLDER_MODAL_SET_PAGE_SIZE', { pageSize: size });
}

export function setPageNumberAndGo(page: number, loadTable: () => void) {
  flux.dispatch('EXPLORE_FOLDER_MODAL_SET_PAGE_NUMBER', { pageNumber: page });
  loadTable();
}

export function setFolder(folder) {
  flux.dispatch('EXPLORE_FOLDER_MODAL_SET_FOLDER', { folder });
}

export function setShowResults(showResults) {
  flux.dispatch('EXPLORE_FOLDER_MODAL_SET_SHOW_RESULTS', { showResults });
}

export function setTableRoot(root) {
  flux.dispatch('EXPLORE_FOLDER_MODAL_SET_TABLE_ROOT', { root });
}

export function loadItems() {
  if (_.isUndefined(sqExploreFolderModalStore.initialFolderId)) {
    return;
  }

  const limit = sqExploreFolderModalStore.getPageSizeByTable();
  const currentPageNumber = sqExploreFolderModalStore.getPageNumberForTable();
  const offset = (currentPageNumber - 1) * limit;
  const sortDirection = sqExploreFolderModalStore.sortAscending ? 'asc' : 'desc';
  const sortOrder = `${sqExploreFolderModalStore.sortProperty} ${sortDirection}`;
  const root = sqExploreFolderModalStore.tableRoot;
  const params = _.assign({}, sqExploreFolderModalStore.searchParams, {
    limit,
    offset,
    sortOrder,
    root,
    filter:
      sqExploreFolderModalStore.initialFolderId === null && !sqExploreFolderModalStore.searchParams.textSearch
        ? SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC
        : sqExploreFolderModalStore.searchParams.filter,
  });

  flux.dispatch('EXPLORE_FOLDER_MODAL_SET_LOADING', { loading: true });
  return sqFoldersApi
    .getFolders(params)
    .then((response) => {
      flux.dispatch('EXPLORE_FOLDER_MODAL_SET_ITEMS', {
        items: response.data?.content,
      });
      flux.dispatch('EXPLORE_FOLDER_MODAL_SET_ITEM_TOTAL_FOR_TABLE', {
        itemTotalForTable: response.data?.totalResults,
      });
    })
    .finally(() => flux.dispatch('EXPLORE_FOLDER_MODAL_SET_LOADING', { loading: false }));
}

export function getRootAndUpdateFilter(folderId) {
  if (folderId) {
    return sqFoldersApi.getAncestors({ folderId }).then((folderTree) => {
      const itemFolderRoot = folderTree.data.id;
      if (
        _.includes(
          [SEARCH_ITEM_LOCATIONS.CORPORATE, SEARCH_ITEM_LOCATIONS.SHARED_OR_PUBLIC, SEARCH_ITEM_LOCATIONS.MY_FOLDER],
          itemFolderRoot,
        )
      ) {
        exportForTesting.setTableFilter(itemFolderRoot);
        exportForTesting.setSearchParams('filter', itemFolderRoot);
      }
    });
  } else {
    return Promise.resolve({});
  }
}

/**
 * @param {Boolean} forceCorporate - checks if the function is being called within the corporate drive message,
 * which will default the folder to being the corporate drive
 */
export function getInitialFolder({
  currentTab,
  item,
  forceCorporate = false,
  insideAdvancedSearch = false,
  isCurrentFolderNested = false,
  breadcrumbsPresent = false,
  breadcrumbClicked = false,
  locationChangedInAdvanced = false,
  currentFolderId = '',
  locationValue = '',
}) {
  let tabFolderPromise;
  let tableFilter = currentTab;
  const tabFolder = _.includes(tabFolders(isAdmin(), isEveryoneGroupEnabled()), currentTab) ? currentTab : undefined;

  getTabFolder(tabFolder).then((folder) => {
    let updatedFolder = _.cloneDeep(folder);
    if (
      (item !== undefined && !forceCorporate && insideAdvancedSearch) ||
      (item && !equalsIgnoreCase(item.parentFolderId, updatedFolder?.id) && !forceCorporate)
    ) {
      tabFolderPromise = getTabFolderName(item.ancestors[0]?.id);
      tabFolderPromise.then((tabName) => {
        tableFilter = item.ancestors[0]?.id ? tabName : tableFilter;
        updatedFolder = insideAdvancedSearch
          ? item
          : _.assign({}, _.last(item.ancestors), {
              ancestors: _.slice(item.ancestors, 0, item.ancestors.length - 1),
            });
      });
    } else {
      tabFolderPromise = Promise.resolve();
    }

    // If the user is inside a nested folder and they are opening the specific folder modal within the home
    // screen advanced search, then the initial folder needs to be set to the folder of the current folder id.
    // When a user changes the location of the search or clicks on a breadcrumb, the modal should no longer show
    // the contents of the current folder id, so this condition will be skipped over.
    if (
      isCurrentFolderNested &&
      insideAdvancedSearch &&
      breadcrumbsPresent &&
      !breadcrumbClicked &&
      !locationChangedInAdvanced &&
      currentFolderId &&
      !item
    ) {
      return tabFolderPromise.then(() => {
        formatNestedFolderWithAncestors(sqHomeScreenStore.currentFolderId, locationValue, false).then((response) => {
          exportForTesting.setFolder(response);
          exportForTesting.setTableFilter(SEARCH_ITEM_LOCATIONS[tableFilter]);
          exportForTesting.setSearchParams('folderId', response?.id);
          exportForTesting.setInitialFolderId(response?.id);
        });
      });
    } else {
      const advancedSearchId = item ? item?.id : updatedFolder?.id;
      const folderId = item && !forceCorporate && !insideAdvancedSearch ? item?.parentFolderId : advancedSearchId;
      return tabFolderPromise.then(() => {
        exportForTesting.setFolder(updatedFolder);
        exportForTesting.setTableFilter(SEARCH_ITEM_LOCATIONS[tableFilter]);
        exportForTesting.setSearchParams('folderId', folderId);
        exportForTesting.setInitialFolderId(folderId);
      });
    }
  });
}
